/* ///////// For Everypage ////////// */
.min-h-100vh{
  min-height: 100vh;
}

.multiprice div:nth-last-child(2):nth-child(odd){
  width: 100%;
  background: #efefef;
}
.multiprice div{
  width: 47%;
  background: #222;
}
.even-last {
  width: 47%;
}
.odd-last {
  width: 47%;
}
.productlist .divider:first-child{
  display: none;
}
.even-last:last-child{
  width: 47%;
}
.odd-last:last-child{
  width: 100%;
}

/* ///////// Font Size ////////// */
/* 0.875rem = 14px */
.fs-header{
  font-size: 20px;
  font-weight: bold;
}
.fs-subheader{
  font-size: 14px;
}
.fs-title{
  font-size: 16px;
  font-weight: bold;
}
.fs-subtitle{
  font-size: 14px;
}
.fs-content{
  font-size: 0.875rem;
}
.fs-remark{
  font-size: 12px;
}
.fs-empty{
  font-size: 15px;
  text-align: center;
}
.fs-digit{
  font-size: 25px;
  font-weight: bold;
}
.fs-menu-digit{
  font-size: 16px;
  font-weight: bold;
}
.fs-button{
  font-size: 16px;
  padding:3px 0
}
.fs-icon{
  font-size: 25px;
  line-height: 0;
}
.fs-icon-small{
  font-size: 21px;
}
.fs-textfield{
  color: #696d84;
  font-size: 16px;
  padding-left: 10px;
  padding-bottom: 5px;
}
/* ///////// Color or Background ////////// */
.cl-white {
  color: #ffffff;
}

.cl-theme {
  color: #18267C;
}

.cl-theme-light {
  color: #3E63A9;
}

.cl-disabled {
  color: #656565;
}

.cl-label {
  color: #96A4C0;
}
.cl-text {
  color: #696d84;
}
.cl-text-dark {
  color: #29292a;
}
.cl-textfield {
  color: #595959;
}
.cl-error{
  color: #bf1d1d;
}

.cl-black-gradient {
  background: -o-linear-gradient(358deg, #000 0.81%, rgba(7, 7, 7, 0.70) 53.3%, #000 104.75%);
  background: linear-gradient(92deg, #000 0.81%, rgba(7, 7, 7, 0.70) 53.3%, #000 104.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-theme {
  background: #18267C;
}

.bg-dialog{
  background: #e8e9f3;
}

.cl-theme-dark {
  color: #16114E;
}

.bg-base {
  background: #F4F4F4;
}

.bg-pearl-white{
  background: #F6F7F9;
}

.border-white {
  border-color: #fff;
}

.border-theme {
  border-color: #18267C;
}

/* ///////// Button ////////// */
.btn-square20-theme, .btn-square25-theme , .btn-square30-theme {
  display: flex;
  align-items: center;
  justify-content: center;
  background:#18267C;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  font-size: 16px;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(93, 132, 209, 0.15), -1px -1px 2px 0px rgba(0, 0, 0, 0.23) inset, 1px 1px 2px 0px rgba(157, 237, 255, 0.43) inset;
  box-shadow: 2px 2px 2px 0px rgba(93, 132, 209, 0.15), -1px -1px 2px 0px rgba(0, 0, 0, 0.23) inset, 1px 1px 2px 0px rgba(157, 237, 255, 0.43) inset;
}
.btn-square20-white, .btn-square25-white, .btn-square30-white {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background:#fff;
  color:#18267C;
  border-radius: 3px;
  font-size: 16px;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.07);
}
.btn-square20-theme, .btn-square20-white{
  width:20px;
  height:20px;
  min-width: 20px;
}

.btn-square25-theme, .btn-square25-white{
  width:25px;
  height:25px;
  min-width: 25px;
}

.btn-square30-theme, .btn-square30-white{
  width:30px;
  height:30px;
  min-width: 30px;
}


.btn-outlined-theme,
.btn-outlined-white{
  padding: 6px 20px;
}
.btn-theme,
.btn-white{
  padding: 7px 20px;
}
.btn-outlined-theme,
.btn-outlined-white,
.btn-theme,
.btn-white {
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  border-radius: 48px;
  text-align: center;
}

.btn-outlined-theme {
  border: 2px solid #18267C;
  color: #18267C;
}

.btn-outlined-white {
  border: 1px solid #fff;
  color: #fff;
}

.btn-theme {
  background: #18267C;
  color: #fff;
  -webkit-box-shadow: -3px -3px 8px 0px rgba(0, 0, 0, 0.18) inset, 3px 3px 8px 0px rgba(255, 255, 255, 0.25) inset;
  box-shadow: -3px -3px 8px 0px rgba(0, 0, 0, 0.18) inset, 3px 3px 8px 0px rgba(255, 255, 255, 0.25) inset;
}

.btn-white {
  background: #ffffff;
  color: #18267C;
}

/* ///////// Divider ////////// */
.divider-white {
  background: #F6F7F9;
  border-radius: 48px;
  -webkit-box-shadow: 2px 2px 2px 0px #FFF, 0px 1.3px 1px 0px rgba(0, 0, 0, 0.13) inset;
  box-shadow: 2px 2px 2px 0px #FFF, 0px 1.3px 1px 0px rgba(0, 0, 0, 0.13) inset;
}

.menu-shadow {
  -webkit-box-shadow: 0px 8px 10px 0px rgba(124, 151, 255, 0.30), 0px -4px 7px 0px rgba(27, 143, 150, 0.15) inset, 0px 2px 4px 0px rgba(255, 255, 255, 0.45) inset;
  box-shadow: 0px 8px 10px 0px rgba(124, 151, 255, 0.30), 0px -4px 7px 0px rgba(27, 143, 150, 0.15) inset, 0px 2px 4px 0px rgba(255, 255, 255, 0.45) inset;
}

/* ///////// Gradient ////////// */
.gradient-purple {
  background: -o-linear-gradient(348deg, #AC6AFF 0%, #625FE7 96.56%);
  background: linear-gradient(102deg, #AC6AFF 0%, #625FE7 96.56%);
}

.gradient-blue {
  background: -o-linear-gradient(348deg, #6AE0FF 0%, #625FE7 96.56%);
  background: linear-gradient(102deg, #6AE0FF 0%, #625FE7 96.56%);
}

.gradient-orange {
  background: -o-linear-gradient(348deg, #FFCC6A 0%, #E75F5F 96.56%);
  background: linear-gradient(102deg, #FFCC6A 0%, #E75F5F 96.56%);
}

.gradient-purple-text {
  background: -o-linear-gradient(348deg, #AC6AFF 0%, #625FE7 35%, #3935e8 65%);
  background: linear-gradient(102deg, #AC6AFF 0%, #625FE7 35%, #3935e8 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-blue-text {
  background: -o-linear-gradient(348deg, #6AE0FF 0%, #625FE7 35%, #0c08c9 65%);
  background: linear-gradient(102deg, #6AE0FF 0%, #625FE7 35%, #0c08c9 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-orange-text {
  background: -o-linear-gradient(348deg, #FFCC6A 0%, #E75F5F 35%, #870404 65%);
  background: linear-gradient(102deg, #FFCC6A 0%, #E75F5F 35%, #870404 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ///////// Rank ////////// */
/* BRONZE */
.rank-bronze{
  background: -o-linear-gradient(334deg, #B98446 7.86%, #F1D0A7 46.77%, #B98446 91.87%);
  background: linear-gradient(116deg, #B98446 7.86%, #F1D0A7 46.77%, #B98446 91.87%);
}
.rank-bronze-base{
  background: #865028;
}
.rank-bronze-text{
  color: #F1D0A7;
}
.rank-bronze-text-vip{
  color: #865028;
}

/* SILVER */
.rank-silver{
  background: -o-linear-gradient(300deg, #E6F0FF 17.61%, #99B2D6 83.31%);
  background: linear-gradient(150deg, #E6F0FF 17.61%, #99B2D6 83.31%);
}
.rank-silver-base{
  background: #7B9ABF;
}
.rank-silver-text{
  color: #E6F0FF;
}
.rank-silver-text-vip{
  color: #5d7ca0;
}

/* GOLD */
.rank-gold{
  background: -o-linear-gradient(334deg, #F2BB33 7.86%, #FFE485 52.39%, #E0A40E 91.87%);
  background: linear-gradient(116deg, #F2BB33 7.86%, #FFE485 52.39%, #E0A40E 91.87%);
}
.rank-gold-base{
  background: #895720;
}
.rank-gold-text{
  color: #FFE485;
}
.rank-gold-text-vip{
  color: #895720;
}

/* PLATINUM */
.rank-platinum{
  background: -o-linear-gradient(348deg, #E6F0FF 0%, #B38DFF 96.56%);
  background: linear-gradient(102deg, #E6F0FF 0%, #B38DFF 96.56%);
}
.rank-platinum-base{
  background: #887FC6;
}
.rank-platinum-text{
  color: #E6F0FF;
}
.rank-platinum-text-vip{
  color: #746da3;
}


/* ///////// Glass ////////// */
.glass {
  background: rgba(255, 255, 255, 0.72);
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
}

.glass2 {
  background: rgba(255, 255, 255, 0.58);
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
  border-top: 2px solid #ffffff8a;
  border-bottom: 3px solid #ffffff;
}

.glass3 {
  background: -o-linear-gradient(12deg, rgba(255, 255, 255, 0.41) 0.66%, rgba(194, 194, 194, 0.17) 42.29%, rgba(197, 197, 197, 0.17) 85.83%, rgba(241, 241, 241, 0.35) 110.7%);
  background: linear-gradient(78deg, rgba(255, 255, 255, 0.41) 0.66%, rgba(194, 194, 194, 0.17) 42.29%, rgba(197, 197, 197, 0.17) 85.83%, rgba(241, 241, 241, 0.35) 110.7%);
  -webkit-box-shadow: inset 1px 1px 3px 0 #ffffff73, inset -1px -1px 3px 0 #ffffff4f;
  box-shadow: inset 1px 1px 3px 0 #ffffff73, inset -1px -1px 3px 0 #ffffff4f;
}

.box-variant{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background: #EDEDED;
  color: #929292;
  -webkit-box-shadow: 2px 2px 3px 0px #FFF, 1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset;
          box-shadow: 2px 2px 3px 0px #FFF, 1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset;
  border-radius: 5px;
  padding: 3px 8px;
  margin-bottom: 5px;
}

.empty-img{
  width: 150px;
  margin: 0 auto;
}

/* ///////// Box Shadow ////////// */
.shadow-glass2 {
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.13);
}

.shadow-menu {
  -webkit-box-shadow: rgba(124, 151, 255, 0.3) 0px 8px 10px 0px, rgba(27, 143, 150, 0.15) 0px -4px 7px 0px inset, rgba(255, 255, 255, 0.45) 0px 2px 4px 0px inset;
  box-shadow: rgba(124, 151, 255, 0.3) 0px 8px 10px 0px, rgba(27, 143, 150, 0.15) 0px -4px 7px 0px inset, rgba(255, 255, 255, 0.45) 0px 2px 4px 0px inset;
}

.shadow-out {
  -webkit-box-shadow: inset 2px 2px 4px 0 hsl(0deg 0% 100%), 3px 3px 9px 0 rgb(0 0 0 / 7%);
  box-shadow: inset 2px 2px 4px 0 hsl(0deg 0% 100%), 3px 3px 9px 0 rgb(0 0 0 / 7%);
}

.shadow-in {
  -webkit-box-shadow: inset 2px 2px 4px 0 #0002, 4px 4px 7px 0 #ffffff;
  box-shadow: inset 2px 2px 4px 0 #0002, 4px 4px 7px 0 #ffffff;
}
.shadow-txt{
  text-shadow: rgba(255, 255, 255, 0.33) 1px 1px 1px;
}

/* ////////// Scrollbar ///////////*/
.scroll-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/* ////////// Dots ///////////*/
.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #18267C;
  margin: 0 5px;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.custom-dot.active {
  background-color: #18267C;
}

.slick-dots li.slick-active .custom-dot {
  background-color: #18267C;
}

/* ///////// Design ////////// */
/* The ribbon */
.ribbon {
  width: 60px;
  height: 60px;
  background-color: #ffffff70;
  -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.13));
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.13));
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

  /* position: absolute; */
  /* animation:drop forwards .8s 1s cubic-bezier(0.165, 0.84, 0.44, 1); */
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -15.2px;
    border-left: 30px solid #ffffff70;
    border-right: 30px solid #ffffff70;
    border-bottom: 15px solid transparent;
  }
}

.ribbon-side {
  width: 70px;
  height: 19.5px;
  background-color: #ffffff;
  -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.13));
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.13));
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

  /* position: absolute; */
  /* animation:drop forwards .8s 1s cubic-bezier(0.165, 0.84, 0.44, 1); */
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: -8px;
    bottom: 0px;
    height: 100%;
    border-top: 10px solid #ffffff;
    border-bottom: 10px solid #ffffff;
    border-left: 8px solid transparent;
  }
}

.ribbon-side-left {
  width: 70px;
  height: 19.5px;
  background-color: #ffffff;
  -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.13));
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.13));
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

  /* position: absolute; */
  /* animation:drop forwards .8s 1s cubic-bezier(0.165, 0.84, 0.44, 1); */
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    right: -8px;
    bottom: 0px;
    height: 100%;
    border-top: 10px solid #ffffff;
    border-bottom: 10px solid #ffffff;
    border-right: 8px solid transparent;
  }
}

.header-radius {
  border-bottom-left-radius: 60px;
}

.ribbon-small {
  width: 40px;
  height: 50px;
  background-color: #ffffff70;
  -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.13));
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.13));
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

  /* position: absolute; */
  /* animation:drop forwards .8s 1s cubic-bezier(0.165, 0.84, 0.44, 1); */
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -19%;
    width: 100%;
    /* border-left: 20px solid #ffffff70; */
    /* border-right: 20px solid #ffffff70; */
    border-left: 20px solid #ffffff70;
    border-right: 20px solid #ffffff70;
    border-bottom: 10px solid transparent;
  }
}

.product-img-size {
  width: 65px;
  height: 65px;
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 15vw;
  max-width: 15vw !important;
  border-radius: 10px;
  min-width: 65px;
  aspect-ratio: 1 / 1
}
.label-box{
  border-radius: 0px 0px 15px 15px;
  min-width: 60%;
  width: fit-content;
  background: rgba(239, 239, 239, 0.17);
  box-shadow: rgba(0, 0, 0, 0.06) 0px -2px 3px 0px inset, rgb(255, 255, 255) 0px 4px 3px 0px;
  margin: 0px auto;
  text-align: center;
}
.subtitle-box {
  width: 325px;
  max-width: 90%;
  margin: 0px auto;
  text-align: center;
}

/* @keyframes drop{
 0%		{ top:-350px; }
 100%	{ top:0; }
} */

/* ///////// Hover Effect ////////// */
.translateY {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateY:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateX {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.translateX:hover {
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* ///////// Package ////////// */
.package-card {
  background: -o-linear-gradient(304deg, rgb(255 180 38) 0%, rgb(222 134 59) 100%);
  background: linear-gradient(146deg, rgb(255 180 38) 0%, rgb(222 134 59) 100%);
  height: 130px;
  background: '#222';
  border-radius: 15px;
}

.shadow-out {
  -webkit-box-shadow: inset 2px 2px 4px 0 hsla(0, 0%, 100%, .7), 3px 3px 4px 0 rgba(0, 0, 0, .19);
  box-shadow: inset 2px 2px 4px 0 hsla(0, 0%, 100%, .7), 3px 3px 4px 0 rgba(0, 0, 0, .19);
}

.dialog-text p {
  margin: 0;
}

.converted-text p {
  margin: 0;
}

.button-theme {
  background: #18267C !important;
  background-color: #18267C;
  border-radius: 48px;
  color: #fff !important;
  cursor: pointer;
  -webkit-box-shadow: -3px -3px 8px 0px rgba(0, 0, 0, 0.18) inset, 3px 3px 8px 0px rgba(255, 255, 255, 0.25) inset;
  box-shadow: -3px -3px 8px 0px rgba(0, 0, 0, 0.18) inset, 3px 3px 8px 0px rgba(255, 255, 255, 0.25) inset;
  ;
}

.button-theme-outlined {
  background: transparent !important;
  background-color: transparent;
  border: 1px solid #18267C !important;
  border-radius: 48px;
  color: #18267C !important;
  cursor: pointer;
}

.mobile-width-fixed {
  position: fixed;
}

.mobile-width-fixed,
.mobile-width {
  width: 420px !important;
  max-width: 100%;
}

.MuiDialog-paperWidthSm {
  width: 350px !important;
  max-width: 80% !important;
}

.MuiDialog-paperWidthXs-51 {
  width: 350px !important;
  max-width: 80% !important;
}

.product-hover {
  /* border-radius: 15px !important; */
  border: none !important;
  height: 100%;
  position: relative;
  top: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.product-hover:hover {
  -webkit-box-shadow: 0 0 19px 0 #00000029;
  box-shadow: 0 0 19px 0 #00000029;
  top: -5px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.fixed-header-padding {
  padding-top: 60px;
}

.starter-button-style {
  background: rgb(115, 30, 62);
  background: -o-linear-gradient(34deg, rgba(115, 30, 62, 1) 0%, rgba(238, 42, 47, 1) 43%, rgba(245, 129, 78, 1) 100%);
  background: linear-gradient(56deg, rgba(115, 30, 62, 1) 0%, rgba(238, 42, 47, 1) 43%, rgba(245, 129, 78, 1) 100%);
  -webkit-box-shadow: 3px 2px 6px 0 #f4625f87, inset 1px 1px 3px 0 #ffffff8f;
  box-shadow: 3px 2px 6px 0 #f4625f87, inset 1px 1px 3px 0 #ffffff8f;
  cursor: pointer;
  border-radius: 20px;
  color: #fff;
  padding: 13px 28px;
  width: 80%;
  margin: -46px auto 0px;
  text-align: center;
}

#product_images img {
  max-width: 100% !important;
}

/* .MuiOutlinedInput-root {
  border-radius: 48px !important;
  -webkit-box-shadow: 2px 2px 6px 0 #00000026;
  box-shadow: 2px 2px 6px 0 #00000026
} */

.wallet .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.9);
}

.wallet .awssld__content {
  border-radius: 15px;
}

.home .awssld__wrapper {
  border-radius: 15px;
}

.home .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 1) !important;
}

.partner .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.59);
}

.partner .awssld__content {
  background-color: transparent;
  border-radius: 15px;
}

.partner .awssld__wrapper {
  border-radius: 15px;
}

.partnerbanner {
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
  width: 100%;
  border-radius: 15px;
}

/* .awssld__container{
   padding-bottom: calc(var(--slider-height-percentage) * 0.58);
 } */
.awssld__content {
  background-color: #feb940;
}

.shop .awssld__container {
  padding-bottom: calc(var(--slider-height-percentage) * 0.215);
}

.setting-title-bar {
  padding: 5px 15px;
  border-radius: 48px;
  color: #fff
}

img {
  max-width: 100% !important;
}

.txt_truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.txt_truncate1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.txt_truncate2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.86em;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.txt_truncate4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  -webkit-box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

/* .orgchart{
   transform: scale(0.5);
 } */
.orgchart-container {
  border: 2px dashed #d9d9d9;
  margin: 10px 0 0;
}

/* .orgchart-container {
   display: flex;
   justify-content: center;
 } */
 .slick-track {
  margin-left: unset!important;
  margin-right: unset!important;
}

.slick-prev {
  left: 20px !important;
  z-index: 1;
}

.slick-next {
  right: 20px !important;
  z-index: 1;
}

.slick-prev,
.slick-next {
  top: 33px !important
}

.slick-prev:before,
.slick-next:before {
  color: #c2c2c2 !important;
}

.image-gallery-content.fullscreen {
  top: 20vh;
  width: 420px;
  max-width: 100%;
  margin: 0 auto;
}

.announcement-menu {
  max-height: 150px;
  position: relative;
  background-color: #fcfdff !important;
  -webkit-box-shadow: 1px -4px 6px 0px #0000001c;
  box-shadow: 1px -4px 6px 0px #0000001c;
  border-radius: 0 !important;
  border: none !important;
}

.item .transformHeader {
  -webkit-animation-name: tored;
  animation-name: tored;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.item .transformHeaderTransparent {
  -webkit-animation-name: totransparent;
  animation-name: totransparent;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

/* // Traking // */
.tracking-box {
  border: none;
  width: calc(100% - 100px);
  border-radius: 5px;
  height: 43px;
  font-size: 14px;
  padding: 5px 15px !important;
  background: transparent;
  outline: none
}

/* Slider Show for Merchant Listing */
.merchant-slide {
  padding: 10px;
  box-sizing: border-box;
}

.merchant-content {
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
}

.merchant-content img {
  width: 80%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.merchant-content h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

@-webkit-keyframes totransparent {
  0% {
    background-color: #e53935;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes totransparent {
  0% {
    background-color: #e53935;
  }

  100% {
    background-color: transparent;
  }
}

@-webkit-keyframes tored {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: #e53935;
  }
}

@keyframes tored {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: #e53935;
  }
}

@media only screen and (max-width: 600px) {
  .shop img {
    height: 89px;
  }

  .shop .awssld__container {
    padding-bottom: calc(var(--slider-height-percentage) * 0.41);
  }
}